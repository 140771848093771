import React, { useState, useRef, useEffect } from "react";
import { Frame } from "framer";

export default function CanvasItem({
  top,
  left,
  height,
  width,
  fontSize,
  scrollSpeed,
  rotateX,
  rotateY,
  rotateZ,
  project,
  introduction,
  className,

  isHover,
  onClick,
  hoveredProjectId,
  setHoveredProjectId,
  children,
  id,
}) {
  const wrapperRef = useRef();
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(false);
  
  // useEffect(() => {
  //   document.addEventListener("pointermove", handleMove);
  //   document.addEventListener("touchstart", disableScroll);
  //   return () => {
  //     document.removeEventListener("pointermove", handleMove);
  //     document.removeEventListener("touchstart", disableScroll);
  //   };
  // }, []);

  // const disableScroll = () => setAutoScrollEnabled(false);

  const handleMove = (e) => {
    e.preventDefault();
    switch (e.pointerType) {
      case "mouse":
        setAutoScrollEnabled(true);
        setOffset({
          x: window.innerWidth / 2 - e.clientX,
          y: window.innerHeight / 2 - e.clientY,
        });
        break;
      case "touch":
        setAutoScrollEnabled(false);
        break;
      default:
    }
  };

  const speedMultiplier = (scrollSpeed !== undefined ? scrollSpeed : 1) / 100;
  const coordinates = {
    x: offset.x,
    y: offset.y,
  };
  const spring = {
    type: "spring",
    damping: 100,
    stiffness: 100,
  };

  const scaler = Math.log1p(window.innerWidth / 50) * 4.4;
  const handleMouseEnter = () => {
    setHoveredProjectId(id);
  };

  const handleMouseLeave = () => {
    setHoveredProjectId(null);
  };

  return (
    <div
      data-id={introduction ? "intro" : "project"}
      meta-title={id}
      className={`${
        hoveredProjectId === id
          ? "active-hover"
          : hoveredProjectId !== null
          ? "not-active-hover"
          : "item"
      }   ${project?.title === "" ? "hide-hover" : ""}`}
      style={{
        perspective: "2000px",
        pointerEvents: project?.title === "" ? "none" : "inherit",
      }}
    >
      <Frame
        ref={wrapperRef}
        background={""}
        onClick={onClick}
        className={
          className +
          (hoveredProjectId === id
            ? " active-hover"
            : hoveredProjectId !== null
            ? " not-active-hover"
            : "") +
          " item__frame showscale"
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        
       
        
        style={{
          // borderRadius: "10px",
          position: "absolute",
          left: left * scaler,
          top: top * scaler,
          width: width * scaler,
          height: height * scaler,
          fontSize: fontSize * scaler,
          textDecoration: "none !important",
         
          pointerEvents: project?.title === "" ? "none" : "inherit",
        }}
      >
        {children}
      </Frame>
    </div>
  );
}
