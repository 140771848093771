import React from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer__rb: {
    position: "absolute",
    bottom: "1rem",
    zIndex: 9999,
    padding: "0 1.5rem",
    left: 0,
    right: 0,
    width: "100%",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "1rem",
    color: "#fff",
    fontWeight: "bold",
  },
  logo__rb: {
    fontFamily: "Helvetica Neue",

    color: "#fff",
    fontWeight: "bold",
    textDecoration: "none",
    textTransform: "uppercase",
    "& a": {
      textDecoration: "none",
      fontSize: "1rem",
      color: "#fff",
      fontWeight: "bold",
    },
  },
  menu: {
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    fontSize: "1rem",
    fontFamily: "Helvetica Neue",
    "& a": {
      textDecoration: "none",
      fontSize: "1rem",
      color: "#fff",
      fontWeight: "bold",
    },
  },
  typo: {
    fontFamily: "Helvetica Neue, sans-serif",
    fontSize: "1rem",
    color: "#fff",
    fontWeight: "bold",
  },
}));

export default function Nav(props) {
  const classes = useStyles();

  return (
    <div className={classes.footer__rb}>
      <div className={classes.logo__rb}>
        <Link to="/home">REMI BESSE</Link>
      </div>
      <div className={classes.menu}>
        <Link to="/about" className={`link__effect`}>
          About
        </Link>
        {/* <Link to={{ pathname: "/about", state: { intro: false } }}>
          Contact
        </Link> */}
      </div>
    </div>
  );
}
