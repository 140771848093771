import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"
import { motion } from "framer-motion"

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        overflow: "scroll",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    listing: {
        margin: 0,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        listStyleType: "none",
        paddingLeft: 0,
        fontFamily: "'Archivo Black', sans-serif",
        textTransform: "uppercase",
        lineHeight: 1,
        fontSize: "3.222vw",
         "@media (max-width: 900px)": {
            fontSize: "4.3vw",
        },
        "@media (min-width: 1700px)": {
            fontSize: "2vw",
        },
        "@media (max-width: 700px) and (pointer: coarse)": {
            fontSize: "5.8vw",
        },
       
        "@media (max-width: 1000px)and (orientation: landscape) and (pointer: coarse)": {
            fontSize: "3vw",
        },
        "@media (max-width: 700px)and (orientation: landscape) and (pointer: coarse)": {
            fontSize: "3.5vw",
        },
        "& a": {
            textDecoration: "none",
            color: "#fff",
        },
        "@media (max-width: 1000px)": {
            paddingTop: theme.spacing(5)
        },
        "@media (max-width: 630px) and (pointer: coarse)": {
            marginTop: theme.spacing(2.5)
        },
    },
    animationLink: {
        position: "relative",
        "&:after": {
            content: "''",
            height: "1px",
            borderRadius: "4px",
            position: "absolute",
            width: "100%",
            background: "white",
            bottom: "-2%",
            left: 0,
            transform: "scale(0)",
            transition: "transform .3s",
        },
        "&:hover:after": {
            transform: "scale(1)",
            "@media (pointer: coarse)": {
                display: "none",
            },
        },
    }
}))

export default function About() {
    const classes = useStyles();

    const variants = {
        listing: {
            opacity: 0,
            y: "-4%"
        },
        animate: {
            opacity: 1,
            x: 0,
            transition: {
                ease: "easeOut",
                duration: "0.3"
            }
        },
        out: {
            opacity: 0,
            y: "20%",
            transition: {
                duration: 0.3,
                ease: "easeOut"
            }
        }
    }

    return (
        <div key="page/about" className={classes.root}>
            <motion.ul position={"relative"} size={"100%"}
                        variants={variants}
                        initial="listing"
                        animate="animate"
                        exit="out"
                        className={classes.listing}
            >
                <li>
                <Link to={"/home/nike"} className={classes.animationLink}>Nike Train Everyday</Link>
                </li>
                <li>
                <Link to={"/home/vladimir"} className={classes.animationLink}>Vladimir Cauchemar</Link>
                </li>
                <li>
                <Link to={"/home/faces"} className={classes.animationLink}>Faces</Link>
                </li>
                <li>
                <Link to={"/home/complex"} className={classes.animationLink}>Complex</Link>
                </li>
                <li>
                <Link to={"/home/streets"} className={classes.animationLink}>Streets</Link>
                </li>
                <li>
                <Link to={"/home/adidas"} className={classes.animationLink}>Adidas Reboosted</Link>
                </li>
                <li>
                <Link to={"/home/sdm"} className={classes.animationLink}>SDM</Link>
                </li>
                <li>
                <Link to={"/home/giveAFuck"} className={classes.animationLink}>Give a Fuck</Link>
                </li>
                <li>
                <Link to={"/home/accorHotels"} className={classes.animationLink}>AccorHotels</Link>
                </li>
                <li>
                <Link to={"/home/leconsulat"} className={classes.animationLink}>Le Consulat</Link>
                </li>
                <li>
                <Link to={"/home/havana"} className={classes.animationLink}>Havana Club Noche</Link>
                </li>          
            </motion.ul>        
        </div>
    )
}
