import React, { useState, useRef, useEffect } from "react";
import { Scroll, Frame, useAnimation } from "framer";

export default function CanvasScroll(props) {
  const wrapperRef = useRef();
  const animationRef = useRef();
  const scrollEnabled =
    props.scrollEnabled !== undefined ? props.scrollEnabled : true;
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const initialScroll = { x: -400, y: -200 };
  const [scroll, unsafeSetScroll] = useState(initialScroll);
  const [scale, setScale] = useState(1); // Ajoutez l'état pour l'échelle

  const setScroll = ({ x, y }) => {
    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
    unsafeSetScroll({
      x: clamp(x, -props.canvasEnds.right + wrapperRef.current.offsetWidth, 0),
      y: clamp(
        y,
        -props.canvasEnds.bottom + wrapperRef.current.offsetHeight,
        0
      ),
    });
  };

  const handleMove = (e) => {
    e.preventDefault();
    switch (e.pointerType) {
      case "mouse":
        setAutoScrollEnabled(true);
        setMousePosition({ x: e.clientX, y: e.clientY });
        break;
      case "touch":
        setAutoScrollEnabled(false);
        break;
      default:
    }
  };

  useEffect(() => {
    animationRef.current = requestAnimationFrame(scrollMouseAnimation);
    return () => cancelAnimationFrame(animationRef.current);
  });

  const controls = useAnimation();

  const scrollMouseAnimation = () => {
    if (scrollEnabled && autoScrollEnabled && wrapperRef.current != null) {
      const scrollReducer =
        (props.scrollSpeed !== undefined ? props.scrollSpeed : 220) / 1000;
      const translate = {
        x: wrapperRef.current.offsetWidth / 2 - mousePosition.x ,
        y: wrapperRef.current.offsetHeight / 2 - mousePosition.y ,
      };

      setScroll({
        x: scroll.x + translate.x * scrollReducer * 0.065,
        y: scroll.y + translate.y * scrollReducer * 0.065,
      });
      animationRef.current = requestAnimationFrame(scrollMouseAnimation);
    }
  };

  // const handleWheel = (e) => {
  //   e.preventDefault();
  //   const delta = e.deltaY > 0 ? -0.05 : 0.05; // Ajustez la sensibilité pour un zoom plus fluide
  //   setScale((prevScale) => Math.max(0.7, Math.min(1.3, prevScale + delta))); // Limitez l'échelle entre 0.5 et 2
  // };

  return (
    <Frame
      ref={wrapperRef}
      onPointerMove={handleMove}
      // onWheel={handleWheel} // Ajoutez le gestionnaire d'événements pour la molette
      background={"./background3.jpg"}
      height={props.height}
      width={props.width}
    >
      <Scroll
        wheelEnabled={false}
        dragEnabled={!autoScrollEnabled}
        onDrag={() => setAutoScrollEnabled(false)}
        onScrollStart={(info) => {
          setScroll({
            x: info.point.x - 0.000000001,
            y: info.point.y - 0.000000001,
          });
        }}
        scrollAnimate={scroll}
        contentOffsetX={initialScroll.x}
        contentOffsetY={initialScroll.y}
        contentWidth={props.canvasEnds.right}
        contentHeight={props.canvasEnds.bottom}
        height="100%"
        width="100%"
        transition={{ duration: 1.5 }}
        direction="both"
      >
        <div
          style={{
            transform: `scale(${scale})`,
            transformOrigin: "center",
            transition: "transform 1s ease", // Ajoutez une transition pour un zoom plus fluide
          }}
        >
          {props.children}
        </div>
      </Scroll>
    </Frame>
  );
}
