import React, { useState, useEffect, useRef } from "react";
import CanvasScroll from "../../components/CanvasScroll";
import VideoIntro from "../../components/projects/VideoIntro";

import { motion } from "framer-motion";

import request from "graphql-request";

import { ProjectItem } from "../../components/ProjectItem";

function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
}

export default function Home(props) {
  const forceUpdate = useForceUpdate();
  const introLaunchTimeoutRef = useRef();
  const [hoveredProjectId, setHoveredProjectId] = useState(null);

  const introFinishedTimeoutRef = useRef();
  const [intro, setIntro] = useState(
    document.documentElement.clientWidth < 600
      ? false
      : props.intro !== undefined
      ? props.intro
      : true
  );
  const introLaunchTimeout = 8000;
  const introDuration = 8000;

  useEffect(() => {
    introLaunchTimeoutRef.current = setTimeout(
      () => setIntro(false),
      introLaunchTimeout
    );

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("resize", forceUpdate);
    return () => {
      clearTimeout(introLaunchTimeoutRef.current);
      clearTimeout(introFinishedTimeoutRef.current);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const [Homepage, setHomepage] = useState(null);

  useEffect(() => {
    const fetchHomepage = async () => {
      const { homepages } = await request(
        "https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clufxzm9c1u7v07tesjxj1rpb/master",
        `
      {
        homepages {
          listprojects(first:100) {
            ... on Project {
              id
              gallery {
                url
                asset {
                  fileName
                  mimeType
                  url
                }
              }
              left
              top
              title
              width
              description { html }
              intro
              featuredAsset {
                id
                url
                mimeType
              }
             
            }
          }
          videoAsset{
                url
                id
          }
          loadingAssets {
            fileName
            url
          }
          metaTitle
          metaDescription
        }
      }`
      );
      console.log(homepages);
      setHomepage(homepages[0]);
    };
    fetchHomepage();
  }, []);

  const introPlaying = () => {
    clearTimeout(introLaunchTimeoutRef.current);
    introFinishedTimeoutRef.current = setTimeout(
      () => setIntro(false),
      introDuration
    );
  };

  const handleMouseEnter = () => {
    if (setHoveredProjectId) {
      setHoveredProjectId(true);
    }
  };

  const handleMouseLeave = () => {
    if (setHoveredProjectId) {
      setHoveredProjectId(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === " " || e.key === "Escape")
      setIntro(false);
  };

  const margins = 100;
  const scaler = Math.log1p(document.documentElement.clientWidth / 50) * 4.4;
  const videoPositionX = 450;
  const videoPositionY = 453;
  const videoWidth = 36;
  const videoHeight = 20;
  const restScreenWidth =
    document.documentElement.clientWidth - videoWidth * scaler;
  const restScreenHeight =
    document.documentElement.clientHeight - videoHeight * scaler;
  const initialScroll = {
    x: 50,
    y: 290,
  };
  const variants = {
    controls: {},
    in: {
      x: "-6%",
      scale: 0.7,
      opacity: 0,
    },
    animate: {
      y: 0,
      x: 0,
      opacity: 1,
      scale: 0.75,
      animation: "scalescroll 0.75s 5s ease-in-out forwards",
    },
    out: {
      scale: 1,
      opacity: 0,
      y: "60vh",
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  return (
    <>
      <motion.div
      // position={"absolute"}
      // variants={variants}
      // initial="in"
      // animate="animate"
      // exit="out"
      // transition={{ duration: 1, ease: "easeOut" }}
      >
        <div className="intro__block">
          <video
            src={Homepage?.videoAsset?.url}
            type="video/mp4"
            autoPlay
            muted
            loop
            className="intro__video"
          />
        </div>
      </motion.div>
      <CanvasScroll
        scroll={initialScroll}
        scrollEnabled={props.scrollEnabled && !intro}
        reset={props.reset}
        canvasEnds={{
          right: 170 * scaler + margins * 2,
          bottom: 130 * scaler + margins * 2,
        }}
        scrollSpeed={70}
        height={"100%"}
        backgroundAsset={"./background3.jpg"}
        width={"100%"}
      >
        <motion.div
          class="motion__canvas"
          style={{ position: "relative", top: margins, left: margins }}
        >
          <motion.div
            position={"relative"}
            size={"100%"}
            variants={variants}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            initial="in"
            animate={"animate"}
            className={hoveredProjectId ? "active-hovered" : ""}
            exit="out"
            transition={{ duration: 0.5, ease: "easeOut" }}
          >
            {Homepage?.listprojects?.map((project) => (
              <ProjectItem
                onMouseEnter={() => handleMouseEnter(project)}
                id={project.id}
                top={project.top}
                left={project.left}
                width={project.width}
                backgroundAsset={project.backgroundAsset}
                featuredAsset={project.featuredAsset}
                title={project.title}
                introduction={project.intro}
                description={project.description}
                project={project}
                key={project.id}
                hoveredProjectId={hoveredProjectId}
                setHoveredProjectId={setHoveredProjectId}
              />
            ))}
          </motion.div>
        </motion.div>
      </CanvasScroll>
    </>
  );
}
