import React, { useEffect, useRef } from "react";

const CrossCanvas = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const drawCross = () => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const container = canvas.parentElement;
      const width = container.offsetWidth;
      const height = container.offsetHeight;

      canvas.width = width;
      canvas.height = height;

      ctx.clearRect(0, 0, width, height);

      ctx.beginPath();
      ctx.moveTo(0, 0);
      ctx.lineTo(width, height);
      ctx.moveTo(width, 0);
      ctx.lineTo(0, height);
      ctx.strokeStyle = "white";
      ctx.lineWidth = 1.5;
      ctx.stroke();
    };

    const delayedDrawCross = () => {
      setTimeout(drawCross, 2000);
    };

    delayedDrawCross();

    window.addEventListener("resize", drawCross);

    return () => {
      window.removeEventListener("resize", drawCross);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 9999,
        width: "100%",
        height: "100%",
      }}
    />
  );
};

export default CrossCanvas;
