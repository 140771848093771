import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";

const useStyles = makeStyles({
  root: {
    height: "100%",
    // borderRadius: "10px",
    "& img": {
      width: "100%",
      // borderRadius: "10px",
    },
  },
});

export default function Image(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <motion.img {...props} onContextMenu={(e) => e.preventDefault()} />
    </div>
  );
}
